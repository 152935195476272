<template>
  <Main :channel="2887211655" />
</template>

<script>
import Main from './Main.vue'
export default {
  components: {
    Main,
  },
  mounted() {
    ;(function (b, a, e, h, f, c, g, s) {
      b[h] =
        b[h] ||
        function () {
          ;(b[h].c = b[h].c || []).push(arguments)
        }
      b[h].s = !!c
      g = a.getElementsByTagName(e)[0]
      s = a.createElement(e)
      s.src = '//s.union.360.cn/' + f + '.js'
      s.defer = !0
      s.async = !0
      g.parentNode.insertBefore(s, g)
    })(window, document, 'script', '_qha', 528685, false)
  },
}
</script>
